<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";
</script>

<style global>@import url("https://fonts.googleapis.com/css2?family=Inconsolata");

:global(:root) {
  --main-theme-color: #2355b9;
}

:global(*) {
  box-sizing: border-box;
}

:global(::-webkit-scrollbar) {
  display: none;
}

:global(html),
:global(body) {
  padding: 0;
  margin: 0;
  position: relative;
  width: 100vw;
  height: 100vh;
  font-size: 12px;
  font-family: "Helvetica", sans-serif;
}

:global(body) {
}

:global(a),
:global(a:visited),
:global(a:link),
:global(a:hover) {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

:global(p) {
  margin: 0;
  padding: 0;
}

:global(div.hero-nav) {
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

:global(div.hero-foot) {
  position: relative;
  width: 100%;
  height: 75px;
  background-color: rgb(255, 255, 255);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

:global(div.hero-foot-mobile) {
  display: none;
  width: 100%;
}

:global(div.hero-foot-mobile) :global(a) {
  margin-left: 2em;
  margin-right: 2em;
  font-size: 1.05em;
}

:global(.ion-logo-instagram) {
  font-size: 1.5em;
}

:global(div.hero-nav-l),
:global(div.hero-nav-r) {
  position: absolute;
  font-size: 1.15em;
}

:global(div.hero-nav-l) {
  left: 2em;
  display: inline;
}

:global(div.hero-nav-l) :global(a) {
  margin-right: 1em;
}

:global(div.hero-nav-r) {
  right: 2em;
}

:global(div.hero-nav-r) :global(a) {
  margin-left: 1em;
}

:global(img.hero-nav-logo) {
  margin: 0;
  padding: 0;
  width: auto;
  height: 2.5em;
  filter: brightness(0);
}

:global(div.hero-grid) {
  margin-top: 75px;
  padding-left: 2em;
  padding-right: 2em;
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

:global(div.hero-grid-item) {
  position: relative;
  width: 100%;
  height: auto;
}

:global(span.hero-grid-link) {
  font-size: 2.5em;
  position: absolute;
  left: 0.5em;
  top: 0.25em;
  vertical-align: middle;
}

:global(span.hero-grid-link) :global(i.icon) {
  font-size: 1.5em;
  margin-left: 0.1em;
  vertical-align: middle;
}

:global(.light) {
  color: white;
}

:global(.dark) {
  color: black;
}

:global(img.hero-grid-img) {
  width: 100%;
  height: auto;
}

:global(div.newsletter) {
  width: 100%;
  padding: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

:global(div.wrap) {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

:global(#signup) {
  width: 55em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

:global(#signup) :global(input) {
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  outline: none;
  padding: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  height: 2em;
  border: 1px solid black;
  border-radius: 2px;
  font-family: Inconsolata, monospace;
  font-weight: 400;
}

:global(#signup) :global(input[type="submit"]) {
  margin-top: 1em;
  padding: 1em;
  height: auto;
  background-color: black;
  color: white;
  cursor: pointer;
}

:global(div.hero-shop) {
  margin-top: 75px;
  width: 100%;
  height: auto;
  padding-left: 2em;
  padding-right: 2em;
}

:global(div.hero-product) {
  margin-top: 75px;
  width: 100%;
  height: calc(100vh - 75px);
  padding-left: 2em;
  padding-right: 2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

:global(div.hero-title) {
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  opacity: 0.5;
}

:global(div.hero-title) :global(button) {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  box-shadow: none;
  margin: 0;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  font-size: 1.15em;
  font-weight: 300;
  background: none;
  border: 1px solid black;
  border-bottom: none;
  border-radius: 4px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: black;
}

:global(div.tab),
:global(div.tab-sm) {
  width: 100%;
  padding: 0.5em;
  font-size: 1.15em;
  border: none;
  border-bottom: 1px solid black;
}

:global(div.tab-sm) {
  width: 15px;
}

:global(div.hero-shop-grid) {
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 1em;
}

:global(#shop-grid-1), :global(#shop-grid-2) {
  margin-bottom: 2em;
}

:global(div.shop-item) {
  width: 100%;
}

:global(img.product-img) {
  width: 100%;
}

:global(p.product-title) {
  margin: 0;
  margin-top: 0.5em;
  margin-bottom: 0.15em;
  padding: 0;
  font-size: 1.15em;
  font-weight: 500;
}

:global(p.product-price) {
  margin: 0;
  padding: 0;
  font-size: 1.15em;
  font-family: Inconsolata, monospace;
  font-weight: 400;
}

:global(div.product-display) {
  width: 70%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em;
}

:global(div.product-grid-item) {
  width: 100%;
  height: auto;
}

:global(img.product-display-img) {
  width: 100%;
  height: auto;
  margin: 0;
  white-space: nowrap;
  margin-bottom: 0.5em;
}

:global(#display-images) {
  display: block;
  height: calc(100vh - 135px);
  overflow: scroll;
}

:global(p.display-title) {
  font-size: 1.5em;
  margin-bottom: 0.25em;
}

:global(p.display-price) {
  font-size: 1.45em;
  font-weight: 400;
  font-family: Inconsolata, monospace;
}

:global(#display-description) {
  padding: 2em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

:global(div.display-description) {
  width: 90%;
  display: block;
  font-size: 1.1em;
}

:global(div.display-description) :global(select) {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid black;
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: 2em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  font-family: Inconsolata, monospace;
  font-weight: 400;
}

:global(div.display-description) :global(button) {
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--main-theme-color);
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  width: 100%;
  height: auto;
  padding: 1em;
  background-color: var(--main-theme-color);
  color: white;
  cursor: pointer;
  margin-bottom: 1em;
}

:global(div.description) {
  font-family: Inconsolata, monospace;
  font-weight: 400;
}

:global(#back-to-store) {
  font-size: 1em;
  font-family: Inconsolata, monospace;
  text-decoration: underline;
  opacity: 0.5;
}

:global(div.hero-cart) {
  position: relative;
  margin-top: 75px;
  width: 100%;
  height: auto;
  padding-left: 2em;
  padding-right: 2em;
}

:global(div.cart-empty) {
  width: 100%;
  height: 100vh;
}

:global(a.cart-wrap) {
  font-size: 1.5em;
  text-decoration: underline;
  font-family: Inconsolata, monospace;
}

:global(div.cart-display) {
  position: relative;
  width: 80%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding-top: 1em;
}

:global(div.cart-display) :global(table) {
  width: 100%;
  table-layout: auto;
}

:global(th),
:global(td.subtotal-title) {
  font-weight: 400;
  font-size: 1.15em;
}

:global(td) {
  padding-bottom: 1em;
}

:global(img.cart-icon) {
  height: 6em;
}

:global(td.cart-item) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

:global(div.cart-product-info) {
  margin-left: 15px;
}

:global(div.cart-product-info) :global(p) {
  font-size: 1.05em;
}

:global(button.remove-from) {
  -webkit-appearance: none;
  margin-top: 0.5em;
  appearance: none;
  outline: none;
  box-shadow: none;
  background: none;
  border: 1px solid rgb(210, 35, 35);
  border-radius: 2px;
  color: rgb(210, 35, 35);
  font-family: Inconsolata, monospace;
  cursor: pointer;
}

:global(td.unit-price),
:global(td.total-price),
:global(td.subtotal) {
  font-family: Inconsolata, monospace;
  font-size: 1.15em;
}

:global(td.subtotal) {
  color: var(--main-theme-color);
}

:global(div.shipping-title) {
  width: 100%;
  font-weight: 400;
  font-size: 1.15em;
  font-family: Inconsolata, monospace;
  color: var(--main-theme-color);
  margin-bottom: 1em;
}

:global(input.qty),
:global(input.mobileQty) {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  box-shadow: none;
  border: 1px solid black;
  border-radius: 2px;
  padding: 7px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 3.5em;
}

:global(input.mobileQty) {
  display: none;
}

:global(button.addQty),
:global(button.removeQty) {
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
  border-radius: 2px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 2px;
}

:global(button.addQty) {
  background-color: var(--main-theme-color);
  border: 1px solid var(--main-theme-color);
  color: white;
}

:global(button.removeQty) {
  border: 1px solid rgb(210, 35, 35);
  color: rgb(210, 35, 35);
}

:global(#checkout-button) {
  -webkit-appearance: none;
  position: relative;
  appearance: none;
  border: 1px solid var(--main-theme-color);
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  width: 25em;
  height: auto;
  padding: 1em;
  background-color: var(--main-theme-color);
  color: white;
  cursor: pointer;
  float: right;
  margin-bottom: 1em;
}

:global(div.hero-information) {
  margin-top: 75px;
  width: 100%;
  height: auto;
  padding-left: 2em;
  padding-right: 2em;
}

:global(div.information-section) {
  width: 70%;
  height: auto;
  margin-bottom: 2em;
}

:global(div.hero-information) :global(h1) {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.25em;
}

:global(div.hero-information) :global(p) {
  margin: 0;
  margin-top: 1em;
  padding: 0;
  font-family: Inconsolata, monospace;
  font-weight: 400;
  font-size: 1.15em;
}

:global(div.information-section) :global(table) {
  margin-top: 1em;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}

:global(div.information-section) :global(tr) :global(td) {
  border: 1px solid black;
}

:global(div.information-section) :global(td),
:global(div.information-section) :global(th) {
  padding: 0.5em;
}

:global(div.information-section) :global(th) {
  text-align: left;
  font-weight: 400;
}

:global(div.information-section) :global(td) {
  font-family: Inconsolata, monospace;
  font-weight: 400;
  font-size: 1.15em;
}

:global(div.information-section) :global(tr) :global(td:first-child) {
  font-family: "Helvetica", sans-serif;
}

@media screen and (max-width: 1000px) {
  :global(div.product-display) {
    width: 90%;
  }
  :global(div.hero-shop-grid) {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 800px) {
  :global(div.hero-nav-l) {
    left: 1em;
  }
  :global(div.hero-nav-r) {
    right: 1em;
  }
  :global(div.hero-grid) {
    padding-left: 1em;
    padding-right: 1em;
    grid-template-columns: repeat(1, 1fr);
  }
  :global(span.hero-grid-link) {
    font-size: 2em;
    left: 0.5em;
    top: 0.25em;
  }
  :global(span.hero-grid-link) :global(i.icon) {
    font-size: 1.5em;
  }
  :global(#signup) {
    width: 100%;
  }
  :global(div.newsletter) {
    padding: 1em;
  }
  :global(div.hero-foot) {
    display: none;
  }
  :global(div.hero-foot-mobile) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :global(#hero-foot-1) {
    padding-top: 1em;
  }
  :global(#hero-foot-2) {
    padding-top: 1em;
    padding-bottom: 2em;
  }
  :global(div.hero-shop) {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 700px) {
  :global(div.hero-product) {
    height: auto;
    padding-left: 1em;
    padding-right: 1em;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  :global(div.hero-shop),
  :global(div.hero-cart),
  :global(div.hero-information) {
    padding-left: 1em;
    padding-right: 1em;
  }
  :global(div.product-display) {
    width: 100%;
    height: auto;
    grid-template-columns: repeat(1, 1fr);
  }
  :global(div.information-section) {
    width: 100%;
  }
  :global(#display-images) {
    height: auto;
  }
  :global(img.product-display-img:last-child) {
    margin-bottom: none;
  }
  :global(#display-description) {
    padding: 0em;
    display: block;
  }
  :global(div.display-description) {
    width: 100%;
  }
  :global(p.display-title) {
    font-size: 1.65em;
  }
  :global(p.display-price) {
    font-size: 1.35em;
  }
  :global(div.cart-display) {
    width: 100%;
  }
  :global(#checkout-button) {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  :global(div.hero-shop-grid) {
    grid-template-columns: repeat(4, 1fr);
  }
  :global(p.cart-product-fn) {
    display: none;
  }
  :global(input.qty) {
    width: 5em;
  }
  :global(button.remove-from) {
    display: none;
  }
}</style>

<Router {routes} />
